<template>
  <el-container class="home_container">
    <!-- 侧边栏 iscollapse是否折叠-->
    <el-aside :style="{ width: iscollapse ? '64px' : '208px' }">
      <div style="height: 80px; line-height: 100px; text-align: left">
        <img src="../../assets/imgs/logo.png" v-if="!iscollapse" alt="" />
      </div>
      <!-- 侧边栏菜单区域 collapse 是否折叠,开启路由模式-->
      <el-menu
        background-color="#001529"
        text-color="#fff"
        active-text-color="#409EFF"
        :unique-opened="true"
        :collapse="iscollapse"
        :collapse-transition="false"
        :router="true"
        :default-active="activepath"
      >
        <!-- 一级菜单 -->
        <el-submenu
          :index="item.id + ''"
          v-for="item in menulist"
          :key="item.id"
        >
          <!-- 一级菜单的模板区域 -->
          <!-- 文本 -->
          <template slot="title">
            <i :class="item.ico"></i>
            <span class="menustyle">{{ item.menuName }}</span>
          </template>

          <!-- 二级菜单 :router="true"路由导航：subitem.path-->
          <el-menu-item
            :index="subitem.path"
            v-for="subitem in item.submenulist"
            :key="subitem.id"
            @click="saveNavState(subitem.path)"
            class="menustyle"
          >
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>{{ subitem.menuName }}</span>
            </template>
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <!-- 菜单栏折叠和展开效果 -->
      <div
        class="toggle-button"
        @click="toggleCollaspse"
        :style="{ width: iscollapse ? '64px' : '208px' }"
      >
        |||
      </div>
    </el-aside>

    <!-- 页面主体区域 -->
    <el-container>
      <!-- 头部区域 -->
      <el-header
        style="
          height: 50px;
          margin-top: 20px;
          margin-right: 20px;
          text-align: right;
        "
      >
        <el-dropdown>
          <el-link :underline="false" icon="el-icon-user-solid" class="link1"
            >{{ adminname }}<i class="el-icon-arrow-down el-icon--right"></i
          ></el-link>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changepassword"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item @click.native="logout">退出</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <el-divider></el-divider>
      </el-header>
      <!-- 右侧内容主体 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>

    <!-- 修改密码对话框-->
    <div v-show="ChangeDialogVisible == true">
      <el-dialog
        :close-on-click-modal="false"
        title="修改密码"
        :visible.sync="ChangeDialogVisible"
        width="30%"
        @close="ChangeDialogClosed"
        center
      >
        <el-form
          :model="changeForm"
          :rules="changeFormRules"
          ref="changeFormRef"
          label-width="80px"
        >
          <div v-if="Ifadmin == false">
            <el-form-item label="用户名" prop="username">
              <el-input
                prefix-icon="el-icon-user-solid"
                v-model="changeForm.username"
                disabled
              ></el-input>
            </el-form-item>
          </div>
          <div v-else>
            <el-form-item label="用户名" prop="username">
              <ELselect
                v-model="changeForm.username"
                :options="usernameOptions"
                :optionKey="usernameOptionKeys"
                Splaceholder="请选择用户"
              ></ELselect>
            </el-form-item>
          </div>

          <!-- <el-form-item label="用户名" prop="name">
                <el-input v-model.trim="username" disabled></el-input>
              </el-form-item> -->
          <el-form-item label="旧密码" prop="oldpassword">
            <el-input
              show-password
              v-model="changeForm.oldpassword"
              prefix-icon="el-icon-unlock"
              type="password"
              clearable
              placeholder="请输入旧密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              show-password
              v-model="changeForm.password"
              prefix-icon="el-icon-unlock"
              type="password"
              clearable
              placeholder="请输入新密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="password2">
            <el-input
              show-password
              v-model="changeForm.password2"
              prefix-icon="el-icon-unlock"
              type="password"
              clearable
              placeholder="请再一次输入密码"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer">
          <el-button @click="Homepath">取消</el-button>
          <el-button type="primary" @click="confirmTochange">确定</el-button>
        </span>
      </el-dialog>
    </div>
  </el-container>
</template>

<script>
import { logoutUser, changepwd, getUsers } from '@/api/systemanager/user'
import ELselect from '@/components/myself/el_select.vue'
import ListenEvent from '@/libs/ListenEvent.js'

export default {
  components: { ELselect },
  data () {
    var validatePwd = (rule, value, callback) => {
      if (/^[a-zA-Z0-9]+$/.test(value) === false) {
        callback(new Error('密码必须是数字或英语+数字组成'))
      } else {
        callback()
      }
    }
    var validatePwd2 = (rule, value, callback) => {
      if (value !== this.changeForm.password) {
        callback(new Error('两次密码不匹配!'))
      } else {
        callback()
      }
    }
    return {
      // 左侧菜单数据
      menulist: [
        {
          id: 2,
          menuName: '系统管理',
          ico: 'el-icon-user',
          submenulist: [
            {
              id: 6,
              menuName: '用户管理',
              path: '/user'
            },
            {
              id: 10,
              menuName: '角色管理',
              path: '/role'
            },
            {
              id: 9,
              menuName: '组织管理',
              path: '/organize'
            },
            {
              id: 11,
              menuName: '权限管理',
              path: '/privilege'
            },
            {
              id: 7,
              menuName: '资源管理',
              path: '/system_resource'
            }
          ]
        },
        {
          id: 1021,
          menuName: '用户管理',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 1030,
              menuName: '用户管理',
              path: '/userManage'
            }]
        },

        {
          id: 1,
          menuName: '合作伙伴管理',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 1,
              menuName: '伙伴申请审核',
              path: '/partnerApprove'
            },
            /* {
              id: 4,
              menuName: '伙伴认证审核',
              path: '/partnerRegister'
            }, */
            {
              id: 2,
              menuName: '伙伴管理',
              path: '/partnerManage'
            },
            {
              id: 3,
              menuName: '等级管理',
              path: '/levelManage'
            }
          ]
        },
        {
          id: 4,
          menuName: '商品管理',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 11,
              menuName: '商品上架审核',
              path: '/productApprove'
            },
            {
              id: 21,
              menuName: '商品分类管理',
              path: '/classifyManage'
            },
            {
              id: 31,
              menuName: '商品列表',
              path: '/productList'
            },
            {
              id: 411,
              menuName: '联系信息设置',
              path: '/productContact'
            },
            // {
            //   id: 41,
            //   menuName: '商品数据统计',
            //   path: '/productStatistics'
            // }
          ]
        },

        {
          id: 102,
          menuName: '产品管理',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 103,
              menuName: '产品列表',
              path: '/toolManage'
            }, {
              id: 1031,
              menuName: '低代码套餐管理',
              path: '/IDEtoolManage'
            },]
        },
        // {
        //   id: 1020,
        //   menuName: '学院设置',
        //   ico: 'el-icon-s-cooperation',
        //   submenulist: [
        //     {
        //       id: 1037,
        //       menuName: '视频管理',
        //       path: '/videoManage'
        //     }, {
        //       id: 10317,
        //       menuName: '帮助管理',
        //       path: '/helpManage'
        //     },]
        // },

        {
          id: 1011,
          menuName: '新闻中心',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 1012,
              menuName: '新闻列表',
              path: '/newList'
            }
          ]
        },
        {
          id: 101,
          menuName: '审批设置',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 102,
              menuName: '审批流程',
              path: '/approvalmodel'
            }]
        },
        {
          id: 5,
          menuName: '财务管理',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 1110,
              menuName: '收费设置',
              path: '/priceSet'
            },
            {
              id: 111,
              menuName: '订单管理',
              path: '/orderManage'
            },
            {
              id: 11100,
              menuName: '工单管理',
              path: '/WorksheetManage'
            },
            {
              id: 1111,
              menuName: '交易明细',
              path: '/PayDetail'
            },
            {
              id: 1112,
              menuName: '动账记录',
              path: '/PayCardRecord'
            },

            // {
            //   id: 311,
            //   menuName: '退单审批',
            //   path: '/orderapprove'
            // },
            // {
            //   id: 211,
            //   menuName: '配置项',
            //   path: '/orderset'
            // },
          ]
        },

        {
          id: 10200,
          menuName: '产品营销',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            {
              id: 10370,
              menuName: '活动设置',
              path: '/actionSet'
            }, {
              id: 103170,
              menuName: '营销管理',
              path: '/saleManage'
            },]
        },
        {
          id: 51,
          menuName: '咨询评论管理',
          ico: 'el-icon-s-cooperation',
          submenulist: [
            // {
            //   id: 11108,
            //   menuName: '咨询记录',
            //   path: '/searchRecord'
            // },
            {
              id: 1110,
              menuName: '用户评论',
              path: '/usercomment'
            },
            {
              id: 2110,
              menuName: '评论黑名单',
              path: '/blackmanager'
            }
          ]
        },

        {
          id: 3,
          menuName: '日志管理',
          ico: 'el-icon-monitor',
          submenulist: [
            {
              id: 22,
              menuName: '用户日志',
              path: '/userLog'
            },
            {
              id: 23,
              menuName: 'VIP日志',
              path: '/userVIPLog'
            }
          ]
        }
      ],
      // 是否折叠
      iscollapse: false,
      // 被激活的链接地址
      activepath: '',
      // 修改密码
      ChangeDialogVisible: false,
      changeForm: {
        username: '',
        oldpassword: '',
        password: '',
        password2: ''
      },
      changeFormRules: {
        oldpassword: [
          { required: true, message: '旧密码不能为空', trigger: 'blur' },
          { min: 1, max: 15, message: '长度必须在1-15个字符间', trigger: 'blur' },
          { validator: validatePwd, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '新密码不能为空', trigger: 'blur' },
          { min: 1, max: 15, message: '长度必须在1-15个字符间', trigger: 'blur' },
          { validator: validatePwd, trigger: 'blur' }
        ],
        password2: [
          { required: true, validator: validatePwd2, trigger: 'blur', message: '请输入确认密码' }
        ],
        username: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { min: 5, max: 20, message: '长度必须在5-20个字符间', trigger: 'blur' }
        ]
      },
      usernameOptionKeys: {
        value: 'username',
        label: 'username',
        label2: ''
      },
      usernameOptions: [],
      Ifadmin: false,
      adminname: ''
    }
  },
  mounted () {
    this.activepath = window.sessionStorage.getItem('activepath')
    this.adminname = window.sessionStorage.getItem('username')
    this.getUsernames()

    ListenEvent.$on('goLogin', () => {
      this.$router.push('/login')
      window.sessionStorage.clear()
    })

  },
  methods: {
    // 清空token，并跳转到登录页，重新生成token
    async logout () {
      const res = await logoutUser(window.sessionStorage.getItem('id'))
      if (res.status !== 200) return this.$message.error('退出失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.$message.success('退出成功')
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 修改密码区域
    async changepassword () {
      this.ChangeDialogVisible = true
    },
    ChangeDialogClosed () {
      this.$refs.changeFormRef.resetFields()
    },
    confirmTochange () {
      this.$refs.changeFormRef.validate(async valid => {
        if (valid) {
          const res = await changepwd({ username: this.changeForm.username, password: this.changeForm.password })
          if (res.status !== 200) return this.$message.error('修改密码失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('修改密码成功')
          // this.$router.push('/login')
          if (this.adminname === 'admin') {
            this.$router.push('/home')
          } else {
            this.$router.push('/login')
          }
        }
      })
    },
    Homepath () {
      this.ChangeDialogVisible = false
    },
    async getUsernames () {
      this.changeForm.username = this.adminname
      if (this.adminname === 'admin') {
        this.Ifadmin = true
        const res = await getUsers()
        if (res.status !== 200) return this.$message.error('操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.usernameOptions = res.data.data.map(item => item)
      }
    },
    // ********************
    // 点击按钮，切换菜单的折叠与展开
    toggleCollaspse () {
      this.iscollapse = !this.iscollapse
    },
    // 保存链接的激活状态,保持二级菜单按钮高亮
    saveNavState (activepath) {
      window.sessionStorage.setItem('activepath', activepath)
      this.activepath = activepath
    },
  }
}
</script>
<style lang="less" scoped>
.home_container {
  height: 100%;
}

.el-aside {
  background-color: #001529;

  .el-menu {
    border-right: none;
    text-align: left;

    .menustyle {
      font-size: 16px;
    }
  }
}

.toggle-button {
  height: 50px;
  background-color: #001529;
  font-size: 20px;
  line-height: 50px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
  position: fixed;
  bottom: 0;
}
.link1 {
  font-size: 16px;
}
</style>
